import React from 'react'
import styled from 'styled-components'
import Map from 'components/map'

import { Heading, Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledWrapper = styled.div`
  margin: 80px 0 60px 0;

  ${media.lg.min} {
    margin: 120px 0 0 0;
  }
`

const StyledIntroWrapper = styled.div`
  max-width: 660px;
  margin: 0 auto 40px auto;
  padding: 0 36px;

  ${media.lg.min} {
    margin: 0 auto 60px auto;
  }
`

const StyledHeading = styled(Heading)`
  font-family: 'Open Sans', sans-serif;
`

const StyledMapWrapper = styled.div`
  height: 80vh;
  background-color: ${({ theme }) => theme.colors.lightgray};
`

type Props = {
  heading: string
  text: string
  pinCoordinates: {
    lat: number
    lng: number
  }
}

const MapBig: React.FC<Props> = ({ heading, text, pinCoordinates }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledWrapper>
      <StyledIntroWrapper>
        <StyledHeading
          as="h3"
          dangerouslySetInnerHTML={{ __html: heading }}
          size={lg ? 36 : 22}
          margin={lg ? '25px' : '30px'}
          sizeDiff={0}
          align="center"
        />
        <Text
          dangerouslySetInnerHTML={{ __html: text }}
          size={lg ? 24 : 16}
          line={lg ? '33px' : '22px'}
          align="center"
        />
      </StyledIntroWrapper>
      <StyledMapWrapper>
        <Map
          pinPosition={pinCoordinates}
          mapContainerStyle={{
            width: '100%',
            height: '80vh',
          }}
        />
      </StyledMapWrapper>
    </StyledWrapper>
  )
}

export default MapBig
