import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import SideBySide from 'components/shared/side-by-side'

import Header from 'components/header'
import About from 'components/about'
import BlogPost from 'components/pages/about/blog-post'
import Info from 'components/pages/about/info'
import MapBig from 'components/map-big'
import ContactInfo from 'components/pages/about/contact-info'
import ContactForm from 'components/contact-form'

import { ONasPageQuery } from 'types/graphql'

const ONas: React.FC<PageProps<ONasPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.aboutUsPage
  const PAGE_SEO = data?.wpPage?.seo!

  const LATEST_POST = {
    title: data?.wpPost?.title!,
    slug: data?.wpPost?.slug!,
    date: new Date(data?.wpPost?.date).toLocaleDateString(),
    excerpt: data?.wpPost?.excerpt!,
    image: {
      src: data?.wpPost?.featuredImage?.node?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: data?.wpPost?.featuredImage?.node?.altText,
    },
  }

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Header
        image={{
          src: PAGE?.aboutUsS1Img?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.aboutUsS1Img?.altText!,
        }}
        title={PAGE?.aboutUsS1Title!}
        subtitle={PAGE?.aboutUsS1Paragraph!}
        page="o nas"
      />
      <About
        image={{
          src: PAGE?.aboutUsS2Img?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.aboutUsS2Img?.altText!,
        }}
        title={PAGE?.aboutUsS2Title!}
        text={PAGE?.aboutUsS2Paragraph!}
        buttonText="Sprawdź homeden"
        buttonLink="https://homeden.pl/"
      />
      <BlogPost heading={PAGE?.aboutUsS3Title!} post={LATEST_POST!} />
      <Info
        text={PAGE?.aboutUsS4Title!}
        image={{
          src: PAGE?.aboutUsS4Img?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.aboutUsS4Img?.altText!,
        }}
      />
      <MapBig
        heading={PAGE?.aboutUsS5Title!}
        text={PAGE?.aboutUsS5Paragraph!}
        pinCoordinates={{
          lat: Number(PAGE?.aboutUsS6MapLat!),
          lng: Number(PAGE?.aboutUsS6MapLng!),
        }}
      />
      <SideBySide addMarginBottom>
        <ContactInfo
          title={PAGE?.aboutUsS7Title!}
          address={PAGE?.aboutUsS7Address!}
          email={PAGE?.aboutUsS7Email!}
          phone={PAGE?.aboutUsS7Phone!}
        />
        <ContactForm />
      </SideBySide>
    </Layout>
  )
}

export default ONas

export const query = graphql`
  query ONasPage {
    wpPage(slug: { regex: "/o-nas/" }) {
      seo {
        ...WpSEO
      }
      aboutUsPage {
        aboutUsS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        aboutUsS1Title
        aboutUsS1Paragraph
        aboutUsS2Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        aboutUsS2Title
        aboutUsS2Paragraph
        aboutUsS3Title
        aboutUsS4Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        aboutUsS4Title
        aboutUsS5Paragraph
        aboutUsS5Title
        aboutUsS6MapLat
        aboutUsS6MapLng
        aboutUsS7Title
        aboutUsS7Address
        aboutUsS7Email
        aboutUsS7Phone
      }
    }
    wpPost {
      title
      slug
      date
      excerpt
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1400)
            }
          }
        }
      }
    }
  }
`
