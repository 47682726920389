import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'
import Button from 'components/shared/button'

import media from 'styles/media'

import type { Colors } from 'types/theme'

const StyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 120px;

  ${media.lg.max} {
    padding: 80px 0;
  }
`

const StyledHeading = styled.h2`
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 0;
  padding: 0 36px;

  ${media.lg.min} {
    font-size: 34px;
    padding: 0;
  }
`

const StyledSeeAllLink = styled(Link)`
  display: block;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.midnightgreen};
  font-weight: 600;
  width: fit-content;
  padding: 10px;
  margin: 20px 26px 10px auto;

  ${media.lg.min} {
    font-size: 20px;
    padding: 0;
    margin: 0 0 20px auto;

    &:hover {
      text-decoration: underline;
    }
  }
`

const StyledPostCardWrapper = styled.div<{ bgColor: keyof Colors }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.pewterblue};

  ${media.lg.min} {
    button {
      visibility: hidden;
      opacity: 0;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    &:hover {
      button {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`

const StyledPostCardImageWrapper = styled.div`
  height: 200px;

  ${media.lg.min} {
    height: 500px;
  }
`

const StyledPostCardContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    height: 1px;
    left: 0;
    top: 35px;
    width: 21px;

    ${media.lg.min} {
      top: 50px;
      width: calc(9% - 20px);
    }
  }

  &:after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    height: 1px;
    right: 0;
    top: 35px;
    width: 120px;

    ${media.lg.min} {
      top: 50px;
      width: calc(24% - 20px);
    }
  }
`

const StyledTitle = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  line-height: 22px;
  padding: 25px 89px 0 36px;

  ${media.lg.min} {
    padding: 40px 24% 0 9%;
    font-size: 22px;
    line-height: 30px;
  }
`

const StyledDateAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 36px 30px 36px;

  ${media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 36px 30px 9%;
  }
`

const StyledExcerpt = styled.p`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 15px;
  font-size: 15px;
  padding: 0 89px 0 36px;

  ${media.lg.min} {
    font-size: 18px;
    line-height: 1.4;
    padding: 0 24% 0 9%;
  }
`

const StyledDate = styled.p`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.min} {
    margin-top: 0;
  }
`

const StyledReadMoreButton = styled(Button)`
  margin-top: 30px;
  background: transparent;
  border-color: white;
  font-size: 16px;

  ${media.lg.min} {
    font-size: 18px;
  }
`

type Props = {
  heading: string
  post: any
}

const BlogPost: React.FC<Props> = ({ heading, post }) => {
  return (
    <StyledContainer>
      <StyledHeading dangerouslySetInnerHTML={{ __html: heading }} />
      <StyledSeeAllLink to="/blog/">Zobacz wszystkie</StyledSeeAllLink>
      <StyledPostCardWrapper
        bgColor="pewterblue"
        as={Link}
        to={`/blog/${post?.slug}/`}
      >
        <StyledPostCardImageWrapper>
          <LazyImage src={post?.image?.src} alt={post?.image?.alt} fill />
        </StyledPostCardImageWrapper>
        <StyledPostCardContentWrapper>
          <StyledTitle dangerouslySetInnerHTML={{ __html: post?.title }} />
          <StyledExcerpt dangerouslySetInnerHTML={{ __html: post?.excerpt }} />
          <StyledDateAndButtonWrapper>
            <StyledDate>{post?.date}</StyledDate>
            <StyledReadMoreButton>Czytaj więcej</StyledReadMoreButton>
          </StyledDateAndButtonWrapper>
        </StyledPostCardContentWrapper>
      </StyledPostCardWrapper>
    </StyledContainer>
  )
}

export default BlogPost
