import React from 'react'
import styled from 'styled-components'
import { ImageDataLike } from 'gatsby-plugin-image'

import { Heading } from 'components/shared/typography'
import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledWrapper = styled.div`
  position: relative;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  img {
    object-position: 0 13%;
  }
`

const StyledContainer = styled(Container)`
  padding-top: 66px;
  padding-bottom: 66px;

  ${media.lg.min} {
    padding-top: 144px;
    padding-bottom: 144px;
  }
`

type Props = {
  text: string
  image: {
    src: ImageDataLike
    alt: string
  }
}

const Info: React.FC<Props> = ({ text, image }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledWrapper>
      <Background>
        <LazyImage src={image.src} alt={image.alt} fill />
      </Background>
      <StyledContainer>
        <Heading
          dangerouslySetInnerHTML={{ __html: text }}
          as="h2"
          size={lg ? 34 : 22}
          line={lg ? '55px' : '45px'}
          sizeDiff={0}
        />
      </StyledContainer>
    </StyledWrapper>
  )
}

export default Info
